import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../components/LayoutDAI'
import AnnouncementBanner from '../../components/dai/AnnouncementBanner'
import Hero from '../../components/dai/Hero'
import Section from '../../components/dai/Section'
import IconCard from '../../components/dai/IconCard'
import { Heading1 } from '../../components/dai/Heading1'
import { Heading2 } from '../../components/dai/Heading2'
import { Text } from '../../components/dai/Text'
import Columns from '../../components/dai/Columns'
import Column from '../../components/dai/Column'
import PageNavigation from '../../components/dai/PageNavigation'
import { BoldText } from '../../components/dai/BoldText'

import SEO from '../../components/SEO'
import Space from '../../components/common/Space'
import TextLink from '../../components/common/TextLink'

import PhotoVideo from '../../images/dai/icons/assets/photo-video.svg'
import BrokenComputer from '../../images/dai/icons/assets/broken-computer.svg'
import TestFailed from '../../images/dai/icons/assets/test-failed.svg'

import ShoppingCart from '../../images/dai/icons/assets/shopping-cart.svg'
import Coins from '../../images/dai/icons/assets/coins.svg'
import Globe from '../../images/dai/icons/assets/globe.svg'
import Page from '../../images/dai/icons/assets/page.svg'
import ShieldCheckmark from '../../images/dai/icons/assets/shield-checkmark.svg'
import Capital from '../../images/dai/icons/assets/capital.svg'

const QuoteText = styled(Text)`
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${props => props.theme.color.purple800};
  font-weight: bold;
`

export default function ExecutiveSummary() {
  return (
    <LayoutDAI pagePath={{ parent: 'executive-summary', child: '' }}>
      <SEO
        title="Executive Summary | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/executive-summary/"
        desc="Discover the top barriers and roadblocks keeping disabled users from reaching their digital destination with AudioEye's 2023 Digital Accessibility Index."
        bannerUrl="https://images.prismic.io/audioeye-web/cd3a6d14-5304-4529-a703-f31faa7c4dd8_introduction.png?auto=compress,format"
        imageAlt="Cars in a bird’s eye view driving down 3 intertwining roads. The roads sit on top of a grid of lines, that resemble a trending line chart."
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/executive-summary/`}
      />
      <AnnouncementBanner />
      <Hero
        image={
          <StaticImage
            src="../../images/dai/hero/introductions.png"
            alt="Cars in a bird’s eye view driving down 3 intertwining roads. The roads sit on top of a grid of lines, that resemble a trending line chart. "
          />
        }
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          EXECUTIVE SUMMARY
        </Text>
        <Space height={24} />
        <Heading1 heading1>2023 Digital Accessibility Index</Heading1>
        <Space height={32} />
        <Text largeBody>
          Top barriers and roadblocks keeping disabled users from reaching their digital destination
        </Text>
      </Hero>
      <Section paddingTop="md" constraint="lg" innerConstraint="md">
        <Text>
          For people with disabilities, navigating an inaccessible website can feel like driving down a bumpy, broken
          road. Getting from point A to point B should be easy, but accessibility issues (the potholes and closed roads
          of the digital world) can slow people down — or keep them from reaching their digital destination.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" innerConstraint="md">
        <Text largeCapsInter color="orange900">
          A WORD FROM THE CEO
        </Text>
        <Space height={16} />
        <Heading2 heading2>The need for a Digital Accessibility Index</Heading2>
        <Space height={16} />
        <Text largeBody>
          <BoldText>David Moradi, CEO, AudioEye</BoldText>
        </Text>
        <Space height={32} />
        <Text>
          I have a vision of a digital future where people with disabilities can easily accomplish any task online, from
          shopping to managing their health and finances.
        </Text>
        <Space height={32} />
        <Text>
          Unfortunately, digital experiences today are often broken for the 1.3 billion people globally who live with a
          disability. And while many companies have some digital accessibility efforts in place, they tend to be
          insufficient — which is one reason just 3% of the web is accessible for people with disabilities.
        </Text>
        <Space height={32} />
        <Text>
          We wanted to identify where some of the world’s leading brands fall short on accessibility, so we created the
          first <BoldText>Digital Accessibility Index</BoldText>. It combines data-backed findings from our scan of
          nearly 40,000 enterprise websites with additional insights from manual audits of the top sites in key
          industries like retail, media, and travel.
        </Text>
        <Space height={32} />
        <Text>
          The results were staggering. Across the 2 million pages scanned, we found an average of{' '}
          <BoldText>37 accessibility errors per page</BoldText>. These errors ranged in severity, from issues that can
          force people with disabilities to spend extra time accomplishing a task to complete blockers that have no
          workaround — and often result in lost customers and revenue.
        </Text>
        <Space height={32} />
        <Text>
          After reviewing the entire data set, our team identified three areas that are especially problematic for
          people with disabilities: image accessibility, link accessibility, and form accessibility.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" innerConstraint="lg">
        <Columns unorderedList>
          <Column size={8} tabletSize={12} listItem>
            <IconCard noCard>
              <PhotoVideo />
              <Space height={16} />
              <Text xLargeBodyBold>56%</Text>
              <Space height={16} />
              <Text smallBodyMedium>
                <BoldText>of images are not accessible</BoldText> to people with visual impairments, which can prevent
                them from being able to understand or benefit from any information conveyed by the image.
              </Text>
            </IconCard>
          </Column>
          <Column size={8} tabletSize={12} listItem>
            <IconCard noCard>
              <BrokenComputer />
              <Space height={16} />
              <Text xLargeBodyBold>64%</Text>
              <Space height={16} />
              <Text smallBodyMedium>
                <BoldText>of pages have links that are not clear</BoldText> to people with some visual and cognitive
                impairments, which can make it difficult for them to navigate between pages or find the information they
                need.
              </Text>
            </IconCard>
          </Column>
          <Column size={8} tabletSize={12} listItem>
            <IconCard noCard>
              <TestFailed />
              <Space height={16} />
              <Text xLargeBodyBold>25%</Text>
              <Space height={16} />
              <Text smallBodyMedium>
                <BoldText>of forms are missing clear labels</BoldText>, which can make it difficult for people with
                disabilities to know what to enter into each field — impacting key tasks like checkout or account
                creation.
              </Text>
            </IconCard>
          </Column>
        </Columns>
        <Space height={48} />
      </Section>
      <Section constraint="lg" innerConstraint="md">
        <Text>
          And because not every issue can be found with technology alone, we also had our team of certified
          accessibility experts (including members of the disability community) audit the top sites in several
          industries, focusing on key site objectives like checkout pages for retail sites or customer portals for
          financial services sites. Here is a sampling of the significant barriers our testers found:
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" innerConstraint="lg">
        <Columns unorderedList>
          <Column size={8} tabletSize={12} listItem>
            <IconCard noCard>
              <ShoppingCart />
              <Space height={16} />
              <Text heading3 xLargeBodyBold>
                Retail
              </Text>
              <Space height={16} />
              <Text smallBodyMedium>
                On checkout pages, non-sighted users did not receive any audible announcement that a form was missing
                required information — which can leave them unaware that a form submission was unsuccessful.
              </Text>
            </IconCard>
          </Column>
          <Column size={8} tabletSize={12} listItem>
            <IconCard noCard>
              <Coins />
              <Space height={16} />
              <Text heading3 xLargeBodyBold>
                Financial Services
              </Text>
              <Space height={16} />
              <Text smallBodyMedium>
                Keyboard accessibility issues made it hard for screen readers to navigate around pop-up windows (such as
                promotional offers) or access information about account policies and perks.
              </Text>
            </IconCard>
          </Column>
          <Column size={8} tabletSize={12} listItem>
            <IconCard noCard>
              <Globe />
              <Space height={16} />
              <Text heading3 xLargeBodyBold>
                Travel
              </Text>
              <Space height={16} />
              <Text smallBodyMedium>
                Our testers encountered a number of accessibility issues that made it difficult to even look up flight
                times or prices, forcing them to visit other sites where they could start the process of booking travel.
              </Text>
            </IconCard>
          </Column>
          <Column size={8} tabletSize={12} listItem>
            <IconCard noCard>
              <Page />
              <Space height={16} />
              <Text heading3 xLargeBodyBold>
                Media
              </Text>
              <Space height={16} />
              <Text smallBodyMedium>
                Frame titles help users with a screen reader quickly determine what information is on a page, and
                whether they want to keep reading. Yet, nearly 62% of pages on media sites have missing frame titles —
                20% higher than the overall average.
              </Text>
            </IconCard>
          </Column>
          <Column size={8} tabletSize={12} listItem>
            <IconCard noCard>
              <ShieldCheckmark />
              <Space height={16} />
              <Text heading3 xLargeBodyBold>
                Insurance
              </Text>
              <Space height={16} />
              <Text smallBodyMedium>
                Our testers encountered a number of issues that made it difficult to access pricing information, search
                for agents, or update their account information via customer portals.
              </Text>
            </IconCard>
          </Column>
          <Column size={8} tabletSize={12} listItem>
            <IconCard noCard>
              <Capital />
              <Space height={16} />
              <Text heading3 xLargeBodyBold>
                Government
              </Text>
              <Space height={16} />
              <Text smallBodyMedium>
                63% of government pages had at least one interactive element that could not be triggered by a keyboard,
                such as closing pop-up windows or pressing ‘submit’ buttons — all of which would stop a user in their
                tracks.
              </Text>
            </IconCard>
          </Column>
        </Columns>
        <Space height={48} />
      </Section>
      <Section paddingBottom="md" constraint="lg" innerConstraint="md">
        <Text>
          While these issues can feel dire, I’m filled with optimism. More and more companies are prioritizing digital
          accessibility, recognizing both the moral imperative to make the web accessible to all as well as the impact
          accessibility has on the overall user experience, customer loyalty, and revenue. I share the sentiment of a
          member of our <TextLink to="/a11iance-team/" text="disability community" />, who said:
        </Text>
        <Space height={64} />
        <QuoteText>
          “I think the future of digital accessibility is finally starting to look more hopeful. The industry is finally
          waking up to the fact that disabled users need to be included in the process for it to really work.”
        </QuoteText>
        <Space height={64} />
        <Text>
          Please enjoy the inaugural Digital Accessibility Index. We’ve included many more data-backed insights,
          learnings, and best practices that will help your teams evaluate the accessibility of your website and take
          steps to ensure all your website visitors can accomplish their purpose in visiting your site — and reach their
          digital destination.
        </Text>
        <Space height={64} />
        <Text>
          <BoldText>David Moradi</BoldText>
        </Text>
        <Space height={16} />
        <Text>CEO, AudioEye</Text>
      </Section>
      <PageNavigation nextPageLink="/digital-accessibility-index/key-insights/" nextPageText="Key Insights" />
    </LayoutDAI>
  )
}
